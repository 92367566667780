<template>
  <v-container fluid>
    <common-error
      v-if="isError"
      @fetchData="invoiceCommonData"
    />
    <section v-else-if="isLoading">
      <v-row v-for="skeleton in 8" :key="skeleton">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="9">
          <v-skeleton-loader
            type="image"
            height="35"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </section>
    <v-sheet v-else class="pa-4" rounded>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">Gambar</span></v-subheader>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-card
              class="mx-auto"
              outlined
            >
              <v-card
                v-if="!form.imageUrl"
                height="200"
                class="d-flex justify-center align-center"
                flat
              >
                <p class="font-italic red--text" v-if="formUpload.isErrorNullImageUrl">*Gambar wajib di upload*</p>
                <p class="font-italic grey--text" v-else>*Belum upload gambar*</p>
              </v-card>
              <v-img
                v-else
                :src="form.imageUrl"
                contain
                aspect-ratio="1"
                height="200"
                lazy-src="../../assets/images/placeholder-image.png"
              ></v-img>
              <v-card-actions class="white">
                <v-btn
                  v-if="!form.imageUrl"
                  :loading="formUpload.uploadingImage"
                  color="blue-grey"
                  small
                  block
                  class="pa-4 white--text"
                  @click="$refs['file'].$refs.input.click()"
                >
                  Upload Gambar
                  <v-icon
                    right
                    dark
                  >
                    mdi-cloud-upload
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="red"
                  small
                  block
                  class="pa-4 white--text"
                  @click="deleteFile"
                  :loading="formUpload.uploadingImage"
                >
                  Hapus Foto
                  <v-icon
                    right
                    dark
                  >
                    mdi-trash-can
                  </v-icon>
                </v-btn>
              </v-card-actions>
              <v-file-input
                class="d-none"
                v-model="formUpload.file"
                @change="setFileInput"
                ref="file"
                type="file"
                accept="image/*"
                outlined
                dense
              >
              </v-file-input>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">Nama Bank</span></v-subheader>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
              v-model="form.bankName"
              :rules="rules.nameBank"
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">A/C</span></v-subheader>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
              v-model="form.bankAccountName"
              :rules="rules.aCLabel"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">A/N</span></v-subheader
            >
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-model="form.bankAccountNumber"
              :rules="rules.aNLabel"
              v-mask="{'alias': 'custom-integer',rightAlign: false}"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">Penanda Tangan 1</span></v-subheader
            >
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
              v-model="form.firstSignerName"
              :rules="rules.signerOneLabel"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">Jabatan Penanda Tangan 1</span></v-subheader
            >
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
              v-model="form.firstSignerPosition"
              :rules="rules.signatoryOneLabel"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">Penanda Tangan 2</span></v-subheader
            >
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
              v-model="form.secondSignerName"
              :rules="rules.signerTwoLabel"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-subheader><span style="color: red">*</span><span class="ml-1">Jabatan Penanda Tangan 2</span></v-subheader
            >
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
              v-model="form.secondSignerPosition"
              :rules="rules.signatoryTwoLabel"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="userAccess.canCreate">
          <v-col>
            <v-btn elevation="2" small color="primary" class="float-right" @click="submit">
              <b>{{ $_strings.common.SAVE }}</b>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: 'invoice-data-form',
  created() {
    this.invoiceCommonData();
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      valid: false,
      formUpload: {
        file: null,
        base64Image: null,
        isErrorNullImageUrl: false,
        uploadingImage: false,
      },
      form: {
        imageUrl: '',
        bankName: '',
        bankAccountName: '',
        bankAccountNumber: '',
        firstSignerName: '',
        firstSignerPosition: '',
        secondSignerName: '',
        secondSignerPosition: '',
      },
      imageInput: null,
      rules: {
        imageUrl: [
          (v) => !!this.form.imageUrl || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.imageUrlLabel),
        ],
        nameBank: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.nameBankLabel),
        ],
        aCLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.aCLabel),
        ],
        aNLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.aNLabel),
        ],
        signerOneLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signerOneLabel),
        ],
        signatoryOneLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signatoryOneLabel),
        ],
        signerTwoLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signerTwoLabel),
        ],
        signatoryTwoLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signatoryTwoLabel),
        ],
      },
      fileQueueDeletion: [],
      isSubmited: false,
    };
  },
  methods: {
    fileUploadValidation(event, fileSizeMin = 500000) {
      if (!event) return;
      const { size, name } = event;
      const fileType = name.split('.').pop().toLowerCase();
      // 1024000 => 1MB
      const fileTypeSupport = ['jpg', 'jpeg', 'png'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > fileSizeMin) {
        this.$dialog.notify.error('Ukuran file melebihi maksimum 500kb.');
        return false;
      }
      return true;
    },
    setFileInput(file) {
      const valid = this.fileUploadValidation(file);
      if (!valid) {
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        const base64Image = e.target.result;
        this.form.imageUrl = base64Image;
        this.formUpload = {
          file,
          base64Image,
          fileName: file.name,
          isErrorNullImageUrl: false,
        };
      };
    },
    async deleteFile() {
      if (!this.formUpload.file && this.form.imageUrl) {
        try {
          this.formUpload.uploadingImage = true;
          const form = {
            url: this.form.imageUrl,
          };
          this.fileQueueDeletion.push(form);
          this.form.imageUrl = null;
          this.formUpload.file = null;
        } finally {
          this.formUpload.uploadingImage = false;
        }
      } else {
        this.form.imageUrl = null;
        this.formUpload.file = null;
      }
    },
    uploadImage() {
      const dataUser = this.$store.state.user.myUserInfo;
      const { companyId } = dataUser;
      const filePath = `company/${companyId}/invoice_data/`;
      const { base64Image, fileName } = this.formUpload;
      return this.$_services.uploads.uploadImage(base64Image, fileName, filePath)
        .then((result) => {
          this.form.imageUrl = result.data.url;
          if (this.fileQueueDeletion[0]?.url === result.data.url) {
            this.fileQueueDeletion = [];
          }
        }).finally((i) => {
          this.$refs.form.validate();
        });
    },
    async invoiceCommonData() {
      try {
        this.isLoading = true;
        this.isError = false;
        const res = await this.$_services.invoiceData.invoiceCommonData();
        this.form = res.data;
      } catch {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
        return;
      }
      if (!this.formUpload.base64Image && !this.form.imageUrl) return this.$dialog.notify.error('Gambar wajib diupload');
      if (this.formUpload.base64Image) {
        try {
          this.$root.$loading.show();
          await this.uploadImage();
        } catch {
          this.$root.$loading.hide();
          return;
        }
      }
      this.$root.$loading.show();
      this.$_services.invoiceData.invoiceUpdateCommonData(this.form)
        .then(() => {
          if (this.fileQueueDeletion.length) {
            Promise.all(this.fileQueueDeletion.map((payload) => this.$_services.uploads.deleteFile(payload)));
          }
          this.$dialog.notify.success('Berhasil memperbarui data invoice');
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-image {
  color: #b5b5b5;
}
.custom-image::-webkit-file-upload-button {
  padding: 5px 10px 5px 10px;
  border-radius: 15px;
  background-color: white;
  color: #2c59c2;
  border-width: 1px;
  border-color: #2c59c2;
}
</style>
